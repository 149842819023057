<template>
	<div class="contact">
		<div class="container">
			<h1>ติดต่อเรา</h1>

			<div class="contact mt-3">
				<div class="column">
					<contact-info></contact-info>
				</div>
				<div class="column">
					<contact-form></contact-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import ContactInfo from '@/components/contact/ContactInfo';
import ContactForm from '@/components/contact/ContactForm';

export default {
	components: {
		ContactInfo,
		ContactForm
	},
	metaInfo() {
		const title = 'ติดต่อเรา | YELLOWTiRE';
		const desc = 'ติดต่อ สอบถาม ขอข้อมูล ติดต่อโฆษณา ขอเข้าร่วมเป็นร้านค้า YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.contact {
	padding: 15px 0;

	@media only screen and (max-width: $screenMedium) {
		padding-top: 0;
	}

	h1 {
		font-family: 'Open Sans', 'Kanit';
		font-size: 28px;
		font-weight: bold;
		margin-bottom: 0;
		color: #333;
		text-align: center;

		@media only screen and (max-width: $screenMedium) {
			font-size: 22px;
		}
	}

	.contact {
		display: flex;
		flex-wrap: wrap;

		.column {
			width: 50%;

			&:last-child {
				padding-left: 50px;

				@media only screen and (max-width: $screenMedium) {
					padding-left: 0;
				}
			}

			@media only screen and (max-width: $screenMedium) {
				width: 100%;
			}
		}
	}
}
</style>