<template>
    <section class="box">
        <h2>แบบฟอร์มติดต่อ</h2>
        <div class="row">
            <div class="col-sm-12">
                <div class="input-field">
                    <label>ชื่อ-นามสกุล</label>
                    <input type="text"
                        maxlength="100"
                        v-model="form.name"
                        class="square lg" 
                        :class="{ 'invalid': error.name }" />
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="input-field">
                    <label>เบอร์ติดต่อ</label>
                    <input type="text"
                        maxlength="20"
                        v-model="form.tel"
                        class="square lg" 
                        :class="{ 'invalid': error.tel }" />
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="input-field">
                    <label>อีเมล</label>
                    <input type="text"
                        maxlength="150"
                        v-model="form.email"
                        class="square lg" 
                        :class="{ 'invalid': error.email }" />
                </div>
            </div>
            <div class="col-sm-12">
                <div class="input-field">
                    <label>หัวข้อติดต่อ</label>
                    <dropdown
                        v-model="form.title"
                        :classes="{ input: [ 'sqaure', 'lg' , 'input-option' ], 'invalid': error.title }"
                        :options="subject">
                    </dropdown>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="input-field">
                    <label>ข้อความ</label>
                    <textarea rows="4"
                        v-model="form.message"
                        class="square lg" 
                        :class="{ 'invalid': error.message }">
                    </textarea>
                </div>
            </div>
            <div class="mt-3 text-end">
                <button class="btn btn-warning btn-submit" @click="submitContact()">ส่งข้อความ</button>
            </div>
        </div>
    </section>
</template>

<script>
import Helper from '@/services/helper';
import ContactService from '@/services/contactService';

export default {
    data() {
        return {
            subject: this.initSubject(),
            form: this.initForm(),
            error: this.initError()
        }
    },
    props: {
        content: Object
    },
    methods: {
        initSubject() {
            return [{
                id: 1,
                name: 'ติดต่อโฆษณา'
            }, {
                id: 2,
                name: 'ปัญหาการใช้งานเว็บไซด์'
            }, {
                id: 3,
                name: 'เรื่องอื่นๆ'
            }];
        },
        initForm() {
            return {
                name: '',
                tel: '',
                email: '',
                title: '',
                message: ''
            }
        },
        initError() {
            return {
                name: false,
                tel: false,
                email: false,
                title: false,
                message: false
            }
        },
        async submitContact() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await ContactService.submitContact(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.$swal(Helper.completeAlert('ส่งข้อความเรียบร้อย', ''));
                    
                    this.form = this.initForm();
                } else {
					this.$swal(Helper.warningAlert('ไม่สามารถส่งข้อความได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (property == 'title') {
                    isBlank = this.form[property] ? false : true;
                    isValid = !isBlank;
                } else if (!this.form[property]?.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ส่งข้อความไม่สำเร็จ', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.box {
    background: #fff;
    padding: 20px 30px;;
    margin-bottom: 15px;

    h2 {
        font-size: 22px;
        margin-bottom: 22px;
        color: #666;

        @media only screen and (max-width: $screenMedium) {
            font-size: 18px;
        }

        &:after {
            content: " ";
            display: block;
            width: 60px;
            height: 1px;
            margin-top: 10px;
            background: #999;
        }
    }

    label {
        font-size: 13px;
    }

    .input-field {
        input, textarea {
            border-color: #ccc;
        }
    }

    .btn-submit {
		width: 180px;
		height: 42px;
		font-size: 15px;
    }
}

::v-deep .input-field {
    .input-option {
        border-color: #ccc;

        &[readonly] {
            background: #fff;
        }
    }
}
</style>