<template>
    <section>
        <h2>ช่องทางติดต่อ</h2>

        <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15501.99471944333!2d100.5607433!3d13.7487738!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b31429c527ed71a!2sRASA%20TWO!5e0!3m2!1sen!2sth!4v1650249453908!5m2!1sen!2sth" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div class="info">
            <div class="item space-bottom">
                <span>
                    บริษัท เยลโลไทร์ จำกัด
                    <br />
                    อาคารรสา ทู ชั้น 18 เลขที่ 1818 ถนนเพชรบุรี แขวงมักกะสัน เขตราชเทวี กรุงเทพมหานคร 10400 ประเทศไทย
                </span>
            </div>
            <a href="mailto:info@yellowtire.com" class="item" title="info@yellowtire.com">
                <font-awesome-icon :icon="['fas', 'envelope']" class="icon" />
                <span>Email: info@yellowtire.com</span>
            </a>
            <div class="item">
                <font-awesome-icon :icon="['fas', 'phone']" class="icon" />
                <span>Tel: +66 (0) 2026 7407</span>
            </div>
            <a href="https://www.facebook.com/yellowtire" class="item" title="facebook">
                <font-awesome-icon :icon="['fab', 'facebook']" class="icon" />
                <span>Facebook: yellowtire</span>
            </a>
        </div>
    </section>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
    font-size: 22px;
    margin-bottom: 25px;
    color: #666;

    @media only screen and (max-width: $screenMedium) {
        font-size: 18px;
    }

    &:after {
        content: " ";
        display: block;
        width: 60px;
        height: 1px;
        margin-top: 10px;
        background: #999;
    }
}

.map {
    height: 300px;
    margin-bottom: 20px;
    border: 1px solid #666;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.info {
    margin-bottom: 20px;

    .item {
        color: #333;
        margin-bottom: 5px;
        display: block;
        font-size: 13px;
        
        &.space-bottom {
            margin-bottom: 15px;
        }

        .icon {
            color: #aaa;
            margin-right: 15px;
        }
    }
}
</style>